$('.stills').click(function(){
    var iteration = $(this).data('iteration');
    var total = $('.stills').length;
    var previt = iteration - 1;
    if(previt < 1){
        previt = total;
    }
    var nextit = iteration + 1;
    if(nextit > total){
        nextit = 1;
    }
    var image = $(this).data('image');
    var credit = $(this).data('credit');
    $('#stillModal .img-fluid').attr('src', image);
    $('#stillModal .prev').data('previt', previt);
    $('#stillModal .next').data('nextit', nextit);
    $('#stillModal .stillit').text(iteration);
    $('#stillModal .totalit').text(total);
    $('#stillModal .stillcredit').text("");
    if(credit) {
        $('#stillModal .stillcredit').html("Credit: " + credit);
    }
});
$('.prev').click(function(){
    var iteration = $(this).data('previt');
    var total = $('.stills').length;
    var previt = iteration - 1;
    if(previt < 1){
        previt = total;
    }
    var nextit = iteration + 1;
    if(nextit > total){
        nextit = 1;
    }
    var image = $('.stills'+iteration).data('image');
    var credit = $('.stills'+iteration).data('credit');
    $('#stillModal .img-fluid').attr('src', image);
    $('#stillModal .prev').data('previt', previt);
    $('#stillModal .next').data('nextit', nextit);
    $('#stillModal .stillit').text(iteration);
    $('#stillModal .totalit').text(total);
    $('#stillModal .stillcredit').text("");
    if(credit) {
        $('#stillModal .stillcredit').html("Credit: " + credit);
    }
});
$('.next').click(function(){
    var iteration = $(this).data('nextit');
    var total = $('.stills').length;
    var previt = iteration - 1;
    if(previt < 1){
        previt = total;
    }
    var nextit = iteration + 1;
    if(nextit > total){
        nextit = 1;
    }
    var image = $('.stills'+iteration).data('image');
    var credit = $('.stills'+iteration).data('credit');
    $('#stillModal .img-fluid').attr('src', image);
    $('#stillModal .prev').data('previt', previt);
    $('#stillModal .next').data('nextit', nextit);
    $('#stillModal .stillit').text(iteration);
    $('#stillModal .totalit').text(total);
    $('#stillModal .stillcredit').text("");
    if(credit) {
        $('#stillModal .stillcredit').html("Credit: " + credit);
    }
});
